
import { defineComponent } from "vue";
// import { trackerSetAction, trackerSetClick } from "@/utils/vertebraSdk";
// import { gtagEvents } from "@/shared/gtagEvents";
import { useHead } from "@vueuse/head";
// import { data } from "@/shared/products";
import SolutionBannerImage from "@/components/templates/SolutionBannerImage/SolutionBannerImage.vue";
import SolutionContainer from "@/components/templates/SolutionContainer/SolutionContainer.vue";
import { solutionHealthcare } from "../context";

export default defineComponent({
  name: "SolutionMedia",
  components: {
    SolutionBannerImage,
    SolutionContainer
  },
  setup() {
    useHead({
      title: "솔루션 > 헬스케어 - 카카오 i 커넥트 라이브",
      link: [
        {
          rel: "canonical",
          href: `${process.env.VUE_APP_BASE_URL}/solutions/healthcare`
        }
      ]
    });
    // const trackEventSpecLivecall = () => {
    //   gtagEvents("call-상세스펙");
    //   trackerSetClick("go-call-spec");
    // };
    // const { livecall } = data;
    // onMounted(() => {
    //   trackerSetAction("상품 Call 페이지");
    // });
    const solutions = solutionHealthcare;
    return {
      solutions
      // livecall
      // trackEventSpecLivecall
    };
  }
});
